

@import './scss/variables.scss';
@import '~@angular/material/theming';
@include mat-core();

/* Setup  */

@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/Avenir.ttc');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  $white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

/* General Overrides */

html,
body {
  min-height: 100%;
  min-width: 280px;
  margin: 0;
  padding: 0;
}

/* Material Overrides */

.mat-form-field {
  display: block !important;
}
.cdk-global-overlay-wrapper {
  justify-content: center !important;
}
.mat-dialog-container {
  position: relative !important;
}

/* Other related material classes */
.snackbar-info {
  background: $primary;
  color: $white;
  font-family: $acumin-pro;
}

.snackbar-info button {
  font-family: $avenir-black;
  color: $accent;
  font-size: 12px;
  border-radius: 0px;
  :hover, :focus {
    outline: none !important;
  }
}

.snackbar-info .mat-simple-snackbar-action {
  :hover, :focus {
    outline: 1px solid $accent;
  }
}

.snackbar-error {
  background: #DF0C3A;
  color: $white;
  font-family: $acumin-pro;
  font-size: 15px;
}

.snackbar-error button {
  font-family: $avenir-black;
  color: $white;
  font-size: 12px;
  border-radius: 0px;
  :hover, :focus {
    outline: none !important;
  }
}

.snackbar-error .mat-simple-snackbar-action {
  :hover, :focus {
    outline: 1px solid  $white;
  }
}

.snackbar-error .mat-simple-snackbar {
  > span {
    color: $white;
  }
}

.mat-form-field-outline-start, .mat-form-field-outline-end {
  border-radius: 0 !important;
}

/* Global Classes */

@each $name, $color in $colors {
  .background-#{$name} {
    background: $color;
  }
  .color-#{$name} {
    color: $color;
  }
}

.form-field-title {
  font-family: $avenir !important;
  font-weight: bold !important;
  color: $primary;
  font-size: 12px !important;
  &.disabled {
    color: $warn;
  }
}

.mat-dialog-container {
  padding: 0px !important;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}


.icon-blue {
  color: $link-color;
}

.link {
  color: $link-color !important;
  text-decoration: underline;
  cursor: pointer !important;
}

.w-100 {
  width: 100% !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}

.bold {
  font-weight: bold !important;
}

.text-primary {
  color: $primary !important;
}

.border-primary {
  border-color: $primary !important;
}

.text-accent {
  color: $accent;
}

.text-light-grey {
  color: $text-light-grey
}

.white {
  color: $white !important;
}

.font-xs {
  font-size: 8px;
}
.font-sm {
  font-size: 12px;
}
.font-md {
  font-size: 16px;
}
.font-lg {
  font-size: 20px;
}
.font-xl {
  font-size: 32px;
}

.spacer {
  flex: 1 1 auto;
}

.m-xs {
  margin: $spacing-xs !important;
}
.mt-xs {
  margin-top: $spacing-xs !important;
}
.mb-xs {
  margin-bottom: $spacing-xs !important;
}
.mr-xs {
  margin-right: $spacing-xs !important;
}
.ml-xs {
  margin-left: $spacing-xs !important;
}
.m-sm {
  margin: $spacing-sm !important;
}
.mt-sm {
  margin-top: $spacing-sm !important;
}
.mb-sm {
  margin-bottom: $spacing-sm !important;
}
.mr-sm {
  margin-right: $spacing-sm !important;
}
.ml-sm {
  margin-left: $spacing-sm !important;
}
.m-md {
  margin: $spacing-md !important;
}
.mt-md {
  margin-top: $spacing-md !important;
}
.mb-md {
  margin-bottom: $spacing-md !important;
}
.mr-md {
  margin-right: $spacing-md !important;
}
.ml-md {
  margin-left: $spacing-md !important;
}
.m-lg {
  margin: $spacing-lg !important;
}
.mt-lg {
  margin-top: $spacing-lg !important;
}
.mb-lg {
  margin-bottom: $spacing-lg !important;
}
.mr-lg {
  margin-right: $spacing-lg !important;
}
.ml-lg {
  margin-left: $spacing-lg !important;
}
.m-xl {
  margin: $spacing-xl !important;
}
.mt-xl {
  margin-top: $spacing-xl !important;
}
.mb-xl {
  margin-bottom: $spacing-xl !important;
}
.mr-xl {
  margin-right: $spacing-xl !important;
}
.ml-xl {
  margin-left: $spacing-xl !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mlr-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.p-xs {
  padding: $spacing-xs !important;
}
.pt-xs {
  padding-top: $spacing-xs !important;
}
.pb-xs {
  padding-bottom: $spacing-xs !important;
}
.pr-xs {
  padding-right: $spacing-xs !important;
}
.pl-xs {
  padding-left: $spacing-xs !important;
}
.p-sm {
  padding: $spacing-sm !important;
}
.pt-sm {
  padding-top: $spacing-sm !important;
}
.pb-sm {
  padding-bottom: $spacing-sm !important;
}
.pr-sm {
  padding-right: $spacing-sm !important;
}
.pl-sm {
  padding-left: $spacing-sm !important;
}
.pl-24 {
  padding-left: 1.5rem !important;
}
.pr-24 {
  padding-right: 1.5rem !important;
}
.p-md {
  padding: $spacing-md !important;
}
.pt-md {
  padding-top: $spacing-md !important;
}
.pb-md {
  padding-bottom: $spacing-md !important;
}
.pr-md {
  padding-right: $spacing-md !important;
}
.pl-md {
  padding-left: $spacing-md !important;
}
.p-lg {
  padding: $spacing-lg !important;
}
.pt-lg {
  padding-top: $spacing-lg !important;
}
.pb-lg {
  padding-bottom: $spacing-lg !important;
}
.pr-lg {
  padding-right: $spacing-lg !important;
}
.pl-lg {
  padding-left: $spacing-lg !important;
}
.p-xl {
  padding: $spacing-xl !important;
}
.pt-xl {
  padding-top: $spacing-xl !important;
}
.pb-xl {
  padding-bottom: $spacing-xl !important;
}
.pr-xl {
  padding-right: $spacing-xl !important;
}
.pl-xl {
  padding-left: $spacing-xl !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}

.ellipsis {
  display: inline-block;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.background-primary {
  background-color: $primary !important;
}

.background-accent {
  background-color: $accent;
}

.background-white {
  background-color: $white !important;
}

.background-grey {
  background-color: $background-grey !important;
}

.scrollable {
  overflow-y:auto;
}

.capitalize {
  text-transform: capitalize;
}

/* Table configurations */
.table {
  width: 100%;
  max-width: 100vw;
  overflow: auto;
  overflow-y: hidden;
}

.table-extra-large {
  width: 100%;
  min-width: $table-extra-large-min-width;
}


.table-large {
  width: 100%;
  min-width: $table-large-min-width;
}

.table-medium {
  min-width: $table-medium-min-width;
}

.table-small {
  width: 100%;
  min-width: $table-small-min-width;
}


.table-extra-small {
  width: 100%;
  min-width: 600px;
}

.table-extra-large-end {
  @media screen and (max-width: $table-extra-large-min-width) {
    border-left: 1px solid $table-border-color;
  }
}

.table-large-end {
  @media screen and (max-width: $table-large-min-width) {
    border-left: 1px solid $table-border-color;
  }
}

.table-medium-end {
  @media screen and (max-width: $table-medium-min-width) {
    border-left: 1px solid $table-border-color;
  }
}

.table-small-end {
  @media screen and (max-width: $table-small-min-width) {
    border-left: 1px solid $table-border-color;
  }
}

td.mat-column-star {
  width: 20px;
  padding-right: 8px;
}

/* Site custom items */

.page-title {
  font-family: $avenir-black !important;
  font-size: 28px !important;
  letter-spacing: 2.8px !important;
  color: $primary;
  text-transform: uppercase !important;
}

.content-title {
  font-family: $avenir-black !important;
  font-size: 20px;
  color: $primary;
  text-transform: uppercase !important;
  letter-spacing: 2px !important;
}

.content-button {
  border: 1px solid $primary !important;
  border-radius: 0px !important;
  color: $primary;
  font-weight: bold;
  background-color: $white;

  mat-icon {
    font-size: 16px;
    height: 16px;
    width: 16px;
    color: $primary;
  }
  p {
    color: $primary;
    font-size: 14px;
    font-weight: bold;
    margin-top: 4px;
    margin-bottom: 4px;
    font-family: $acumin-pro;
  }

  &.filled:not(:disabled) {
    background-color: $primary !important;
    p {
      color: $white;
      letter-spacing: 0.3px;
    }
    mat-icon {
      color: $white;
    }
  }
  &:disabled {
    opacity: 0.4;
  }
}

.clickable {
  cursor: pointer !important;
}

.table-header {
  font-family: $acumin-pro !important;
  font-size: 15px !important;
  color: $primary !important;
  font-weight: 600 !important;
}

.text-title {
  font-family: $avenir-normal !important;
  font-weight: 900 !important;
  font-size: 12px !important;
  color: $primary !important;
  text-transform: uppercase;
  letter-spacing: 1.2px !important;
}

.text-item {
  font-family: $acumin-pro;
  font-size: 14px;
  letter-spacing: 0.24px;
  color: $primary !important;
}

.input-box {
  border: 1px solid $boarder-grey;
  background-color: $white;
  max-width: 100%;
}

.mat-tab-label-content {
  text-transform: uppercase;
  letter-spacing: 1.3px;
  color: $primary;
  font-family: $avenir;
}

.mat-dialog-container {
  border-radius: 0px !important;
}

.mat-form-field-outline {
  background-color: $white !important;
}

.table-header-white {
  background-color: $white !important;
}

.table-header-grey {
  background-color: $background-grey !important;
}

.mat-tab-label-active {
  .mat-tab-label-content {
    font-family: $avenir-heavy !important;
  }
}

.even-grey {
  thead {
    tr {
      background-color: $background-grey !important;
    }
  }
  tbody {
    .mat-row:nth-child(even){
      background: $background-grey !important;
    }
  }
  mat-paginator {
    background-color: $white !important;
  }

}

.odd-grey {
  thead {
    tr {
      background-color: $white !important;
    }
  }
  tbody {
    .mat-row:nth-child(odd){
      background: $background-grey !important;
    }
  }
  mat-paginator {
    background-color: $white !important;
  }
}

.no-padding-dialog .mat-dialog-container {
  padding-left: 0px;
  padding-right: 0px;
}

.mat-ink-bar {
  background-color: $accent !important;
  height: 3px !important;
}



.mat-ink-bar {
  background-color: rgba(0, 0, 0, 0) !important;
}

a {
  color: $link-color !important;
}

h1, h2, h3, p {
  width: fit-content;
  display: inline-block;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
