/* Material Theming */
@use '@angular/material' as mat;

@include mat.core();
/* For use in src/lib/core/theming/_palette.scss */
$mat-app-primary: (
  50 : #e0e4ea,
  100 : #b3bdca,
  200 : #8091a6,
  300 : #4d6482,
  400 : #264368,
  500 : #00224d,
  600 : #001e46,
  700 : #00193d,
  800 : #001434,
  900 : #000c25,
  A100 : #607cff,
  A200 : #2d52ff,
  A400 : #002bf9,
  A700 : #0027e0,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$mat-app-accent: (
  50 : #fdefe7,
  100 : #fad8c4,
  200 : #f7be9d,
  300 : #f3a476,
  400 : #f19158,
  500 : #ee7d3b,
  600 : #ec7535,
  700 : #e96a2d,
  800 : #e76026,
  900 : #e24d19,
  A100 : #ffffff,
  A200 : #ffe8e1,
  A400 : #ffc1ae,
  A700 : #ffad95,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$mat-app-warn: (
  50 : #fbe2e7,
  100 : #f5b6c4,
  200 : #ef869d,
  300 : #e95575,
  400 : #e43058,
  500 : #df0c3a,
  600 : #db0a34,
  700 : #d7082c,
  800 : #d20625,
  900 : #ca0318,
  A100 : #fff2f3,
  A200 : #ffbfc3,
  A400 : #ff8c94,
  A700 : #ff737c,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$mat-app-theme: mat.define-light-theme(mat.define-palette($mat-app-primary),
    mat.define-palette($mat-app-accent),
    mat.define-palette($mat-app-warn));

$custom-typography: mat.define-typography-config($font-family: 'Avenir, Roboto, "Helvetica Neue", sans-serif',
  );
@include mat.core($custom-typography);
@include mat.all-component-themes($mat-app-theme);

/* Colors */

$primary: #00224D;
$accent: #EE7D3B;
$warn: #D50B37;
$yellow: #B38900;
$light-yellow: #B389001A;
$white: #fff;
$black: #000;
$green: #007A58;
$light-green: #0091681A;
$red: #D50B37;
$light-red: #DF0C3A1A;
$text-light-grey: #616F7D;
$background-grey: #f5f5f5;
$boarder-grey: #E2E2E2;
$table-border-color: #e0e0e0;
$link-color: #0E71C4;
$error-red-background: #DF0C3A0B;

$colors: 'primary'$primary, 'accent'$accent, 'warn'$warn, 'black'$black, 'white'$white;

/* Sizes */

$spacing-xs: 0.5rem;
$spacing-sm: 1rem;
$spacing-md: 2rem;
$spacing-lg: 3rem;
$spacing-xl: 4rem;

/* Transitions */

$transition-reg: 200ms;

/* chips */
$chip-height: 30px;

/* Tables */
$table-extra-large-min-width: 1500px;
$table-large-min-width: 1200px;
$table-medium-min-width: 1000px;
$table-small-min-width: 800px;
$table-min-width: 1100px;

/* Fonts */

$acumin: 'Acumin', "Helvetica Neue", sans-serif;
$acumin-pro: "Acumin-Pro", "Helvetica Neue", sans-serif;
$avenir: 'Avenir', 'Roboto', "Helvetica Neue", sans-serif;
$avenir-black: 'Avenir Black', 'Roboto', "Helvetica Neue", sans-serif;
$avenir-normal: 'Avenir Normal', 'Roboto', "Helvetica Neue", sans-serif;
$avenir-medium: 'Avenir Medium', 'Roboto', "Helvetica Neue", sans-serif;
$avenir-heavy: 'Avenir Heavy', 'Roboto', "Helvetica Neue", sans-serif;
/* Other */

/* Header */
$header-height: 72px;
$content-min-height: calc(100vh - #{$header-height});

// BREAKPOINTS
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;